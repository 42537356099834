import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Split from '../components/Split';

const OpeningPage = ({ data }) => {
  return (
    <Layout>
      <Section
        heading="Opening remarks, by David Middleton, CEO"
      >
        <Split reverse>
          <Img fluid={data.davidImage.childImageSharp.fluid} />
          <div>
            <p>
              Once again the calendar has snuck up on me unannounced and I needed to
              be reminded that it is newsletter time. Too much daydreaming for me.
              I am indeed fortunate to have Claire and Sam to keep me on track. But
              who wouldn’t be tempted to daydream in this beautiful valley? The green
              rolling hills, crisp clear mornings and the calm late afternoons with
              their magical orange light. Kangaroos bouncing past the window, eagles
              gliding by, wattles in flower, cover crops rising lushly and the dormant
              vines fast asleep seemingly unaware of how lucky they are to be a part
              of this landscape.
            </p>
            <p>
              In the newsletter this year I raise a very important topic and one which
              is now front and centre for our industry. This is environmental sustainability.
            </p>
            <p>
              Our industry falls prey to all sorts of influences that are not particularly
              friendly in environmental terms but we are well aware of our responsibilities
              and actively pursuing solutions.
            </p>
          </div>
        </Split>
        <p>
          I also consider the role that the Cabernet family of grapes has played in
          the history of the valley and in the establishment of Mount Mary.  There
          have been many famous families that have figured in the Yarra’s history
          and the Cabernet family is prominent among them. There is Sam’s regular
          vintage report and tasting notes from a standout season. I know each year
          we get excited about the new wines but some vintages delight with quality
          across all varieties. Sam will fill you in on the details a little later.
        </p>
        <Img fluid={data.lakeImage.childImageSharp.fluid} />
        <p>
          This year we finally turned a sod on two very important projects. The
          first is our ‘future proof’ water storage in the form of a 40 megalitre
          lake and the second is our long awaited underground cellar extension. When
          we take these two projects along with phase two of our solar electricity
          installation, we can now see our vision for the future of Mount Mary really
          taking shape. It must have been at least a decade ago that I envisaged us
          becoming independent and self-sufficient for energy and water. That
          dream is now becoming a reality.
        </p>
        <p>
          I do hope you enjoy reading this newsletter. I also trust you will enjoy
          tasting the new wines and of course some older ones. Most of all I look
          forward to rewarding and peaceful times ahead sharing good food and wine
          in fine company.
        </p>
      </Section>
      <Pagination
        forwardLink="/cabernet"
        backLink="/"
      />
    </Layout>
  );
};

OpeningPage.propTypes = {
  data: PropTypes.shape({
    davidImage: PropTypes.object,
    lakeImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    davidImage: file(relativePath: { eq: "DM-intro-pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lakeImage: file(relativePath: { eq: "Lake.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default OpeningPage;
